const config = {
  colorcode: '#ecc400' || '#72f887',
  colordark: '#000000' || '#263d42',
  colorlight: '#ffffff' || '#d7dadb',
  company: 'VanoTracker' || 'vib GmbH',
  logoURI:
    'https://utils.vib-development.ch/vanotracker/logo.svg' || 'https://utils.vib-development.ch/vib/logo.svg',
  address: 'Carlo Vanoli AG,Vorderbruggeten 1,8833 Samstagern'?.split(',') || [
    'vib GmbH',
    'Grabenstrasse 12',
    '6340 Baar',
  ],
  contact: '+41 (0)44 787 81 00,info@vanoli-ag.ch'?.split(',') || [
    '+41 (0)41 763 16 94',
    'support@vib-gmbh.ch',
  ],
  apibaseuri:
    'https://api.manual.vanotracker.ch' ||
    'https://api.main.vib-connect-manual.ch4.amazee.io',
  appname: 'VanoTracker ECM Manual' || 'vib connect Manual',
  appdomain: 'manual.vanotracker.ch' || 'manual.vib-gmbh.ch',
  appurl: 'https://manual.vanotracker.ch' || 'https://manual.vib-gmbh.ch',
  statuspage: 'https://status.vanotracker.ch' || 'https://status.vib-development.ch',
  support: 'support@vanotracker' || 'support@vib-gmbh.ch',
};

export default config;
